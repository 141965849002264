import React, { lazy } from 'react'
import { Router, Redirect } from '@reach/router'

import {
  ACCOUNT,
  DETAIL_PAGE,
  ORDERS,
  USER_CREATE,
  ORDERS_SUCCESS,
  ORDERS_CANCEL
} from '../../containers/App/routes'
import { CHANGEPASSWORD } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/constants'

const NotFound = lazy(() => import('@stackhouse/rassegnastampa-core/lib/pages/NotFound'))
const Account = lazy(() => import('../Account'))
const ArticleDetail = lazy(() => import('../ArticleDetail'))
const Orders = lazy(() => import('../Orders'))
const OrdersSuccess = lazy(() => import('../Orders/success'))
const OrdersCancel = lazy(() => import('../Orders/cancel'))
const Feed = lazy(() => import('../Feed'))
const UsersList = lazy(() => import('../Users/UsersList'))
const EditUser = lazy(() => import('../Users/EditUser'))
const AddUsers = lazy(() => import('../Orders/AddUsers'))
const ChangePassword = lazy(() => import('@stackhouse/rassegnastampa-core/lib/pages/ChangePassword'))

export default () => {
  return (
    <Router>
      <Feed path='/' />
      <Account path={ACCOUNT} />
      <ChangePassword path={CHANGEPASSWORD} />
      <ArticleDetail path={DETAIL_PAGE} />
      <Orders path={ORDERS} />
      <OrdersSuccess path={ORDERS_SUCCESS} />
      <OrdersCancel path={ORDERS_CANCEL} />
      <AddUsers path={USER_CREATE} />
      <UsersList path='users' />
      <EditUser path='users/:userId' />
      <Redirect from="feeds" to="/" noThrow />
      <NotFound default />
    </Router>
  )
}
