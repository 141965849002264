import { all, put, call, takeLatest, select, spawn, delay } from 'redux-saga/effects'
import * as Realm from "realm-web";
import { navigate } from '@reach/router'
import { withLoading } from '@stackhouse/rassegnastampa-core/lib/containers/Loading/withSaga'
import { actions } from '@stackhouse/rassegnastampa-core/lib/containers/StepperState/reducer'
import { actions as actionsAuth } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/reducer'
import { LOGIN, DASHBOARD } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/constants'
import { checkoutStripe } from '@stackhouse/rassegnastampa-core/lib/utils/stripe'
import { getUserStatus } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/selectors'
import app, { mongo } from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { message } from 'antd'
import { generateModelPlan, generateModelUser, generateNextRunAt, formatDateToYYYYMMDD } from '../Orders/utils'
import { actions as actionsReg } from './reducer'

function* save() {
  console.log('REGISTRATION_SAVE')
  const data = yield select(state => state.registration)

  if (app.currentUser) {
    yield delay(500)
    yield put(actions.setCurrentStep({ name: 'registration', stepName: 'step2' }))
    return
  }

  try {
    yield app.emailPasswordAuth.registerUser(data.email.toLowerCase(), data.password)
    const auth = yield app.logIn(Realm.Credentials.emailPassword(data.email.toLowerCase(), data.password));
    const { id } = auth

    yield put(actionsAuth.setLoginData({
      userId: id.toString(),
      masterId: id.toString()
    }))

  } catch (error) {
    console.log(error.statusCode)
    if (error.statusCode === 409) {
      message.error('La mail è già stata utilizzata, se è la tua esegui il login');
    } else {
      message.error('Errore in fase di registrazione');
    }
    return
  }

  yield delay(500)
  yield put(actions.setCurrentStep({ name: 'registration', stepName: 'step2' }))
}

function* registrationCheck() {
  const data = yield select(state => state.registration)
  const incompleteMaster = data.keywords.length === 0 || data.publishers.length === 0

  if(incompleteMaster) {
    message.error('Completa i campi mancanti prima di procedere')
    return;
  }

  yield put(actions.setCurrentStep({ name: 'registration', stepName: 'step3' }))
}

function* complete() {
  console.log('ORDER_COMPLETE')
  const data = yield select(state => state.registration)
  const initialValues = {
    newRegistration: true
  }
  const userData = data.users.reduce((acc, inc) => ([...acc, generateModelUser(inc)]), [])

  const planData = generateModelPlan({ ...data, ...initialValues })
  const incompleteMaster = data.keywords.length === 0 || data.publishers.length === 0

  const orderData = [
    planData,
    ...userData
  ]

  const incomplete = incompleteMaster || orderData.some(e => e.incomplete)

  if(incomplete) {
    message.error('Completa i campi mancanti prima di procedere')
    return;
  }

  try {
    const { insertedId } = yield app.currentUser.functions.createOrder(orderData)
    yield put(actions.setCurrentStep({ name: 'registration', stepName: 'step4' }))
    yield delay(2000)
    yield checkoutStripe(insertedId.toString())
  } catch (error) {
    console.log("function*complete -> error", error)
  }
  //yield app.currentUser.functions.completeRegistration(values)
}

function* updateInterval({ payload }) {
  const { value: repeatInterval, id } = payload

  yield put(actionsReg.updRepeatInterval({
    id,
    value: generateNextRunAt(repeatInterval, formatDateToYYYYMMDD)
  }))
}

function* registrationIncomplete() {
  if (app.currentUser) {
    const customData = yield app.currentUser.refreshCustomData();

    if (customData.status === undefined || customData.status === 'none') { // completa la registrazione
      yield put({ type: '@box/registration/update', payload: { id: 'email', value: app.currentUser.profile.email } })
      yield put(actions.setCurrentStep({ name: 'registration', stepName: 'step1' }))

      yield put(actionsAuth.setLoginData({
        userId: app.currentUser.id.toString(),
        masterId: app.currentUser.id.toString()
      }))
      return
    }

    if (customData.status === 'unpaid') {
      yield put(actionsAuth.setLoginData({
        userId: app.currentUser.id.toString(),
        masterId: app.currentUser.id.toString(),
        status: app.currentUser.status
      }))
      return
      // completa il pagamento
    }

    if (customData.status === 'active') { // se è attivo vai alla dashboard
      yield spawn(navigate, DASHBOARD)
    }
  }
}

export default function* appSaga() {
  yield all([
    yield takeLatest('REGISTRATION_SAVE', withLoading('registration', save)),
    yield takeLatest('REGISTRATION_COMPLETE', withLoading('registration', complete)),
    yield takeLatest('REGISTRATION_UPD_INTERVAL', updateInterval),
    yield takeLatest('REGISTRATION_STEP2', withLoading('registration', registrationCheck)),
    yield takeLatest('REGISTRATION_INCOMPLETE', withLoading('registration', registrationIncomplete))
  ])
}
