import { all, put, takeLatest } from 'redux-saga/effects'
import app from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { actions } from './reducer'
import { actions as actionsAuth } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/reducer'

function* load() {
  try {
    const prices = yield app.currentUser.functions.getPricesList()
    const data = JSON.parse(prices.Payload.toString('utf-8'))
    if (data && Array.isArray(data)) {
      const results = data.reduce((acc, inc) => ({ ...acc, [inc.nickname]: inc.unit_amount }), {})
      yield put(actions.setItems({ results }))
    }
  } catch (error) {
    console.log('error', error)
  }
}

export default function* appSaga() {
  yield all([
    // yield takeLatest([actionsAuth.setLoginData.toString(), 'LOAD_PRICES'], load)
  ])
}
