import { createSelector } from 'reselect'
import { map } from 'lodash'
import _prop from 'lodash/fp/prop'
import { getPublishersByKey } from '../Publishers/selectors'

const selectFeedState = _prop('feeds')
export const getLastTime = createSelector(selectFeedState, _prop('lastTimeLoad'))
const getFeeds = createSelector(selectFeedState, _prop('items'))

export const getFeed = createSelector(
  _prop('feeds.feed'),
  getPublishersByKey,
  (article, publishers) => (
    {
      ...article,
      publisherName: (publishers[article?.publisher]?.name) || '-'
    }
  ))

export const getFeedsForVisualization = createSelector(
  getFeeds,
  getPublishersByKey,
  (feeds, publishers) => map(feeds, (article) => ({
    ...article,
    publisher: publishers[article.publisher]?.name || '-'
  }))
)

export const getViewType = createSelector(selectFeedState, _prop('viewType'))

export const getSearchValue = createSelector(selectFeedState, _prop('search'))
