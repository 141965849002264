import _omit from 'lodash/omit'
import moment from 'moment'
import parser from 'cron-parser'

export const today = new Date().getTime()
export const nextYear = new Date(moment().add(1, 'year')).getTime()

export const formatDateToYYYYMMDD = date => parseInt(moment(date).utc().format('YYYYMMDD'))
export const formatDateToDATEFORMATTED = date => moment(date).utc().format('DD/MM/YYYY')

export const generateNextRunAt = (repeatInterval, formatDateToYYYYMMDD) => {
  const currentDate = moment().utc().toDate()
  const interval = parser.parseExpression(repeatInterval, { currentDate, tz: 'Europe/Rome' });

  return formatDateToYYYYMMDD(interval.next().toDate())
}

export const getUserSubscription = data => {

  if (data.newRegistration) return (
    {
      subscription: {
        start: new Date(data.subscription.start).getTime(),
        end: new Date(data.subscription.end).getTime(),
      },
      nextRunAt: generateNextRunAt(data.repeatInterval, formatDateToYYYYMMDD),
    }
  )

  return (
    {
      subscription: {
        start: data.subscription.start,
        end: data.subscription.end,
      },
      nextRunAt: data.nextRunAt,
    }
  )

}

export const generateModelUser = data => {
  const cleanData = _omit(data, ['_id', 'registeredAt', 'token', 'createdAt'])

  const idValue = {
    id: data.id || (data._id ? data._id.toString() : null)
  }

  const idData = data.newRegistration ? {} : idValue
  const subscriptionData = getUserSubscription(data)

  return (
    {
      ...cleanData,
      ...idData,
      role: data.role || 'user',
      status: data.status || 'none',
      name: data.name || data.email,
      ...subscriptionData
    }
  )
}

export const generateModelPlan = data => {
  return (
    {
      id: 'plan',
      status: data.status || 'none',
      role: data.role || 'master',
      name: `Piano ${data.plan}`,
      email: data.email,
      subscriptionInterval: data.subscriptionInterval,
      repeatInterval: data.repeatInterval,
      plan: data.plan,
      newRegistration: data.newRegistration,
      nextRunAt: data.newRegistration ? generateNextRunAt(data.repeatInterval, formatDateToYYYYMMDD) : data.nextRunAt,
      billing: data.billing,
      keywords: data.keywords || [],
      publishers: data.publishers || [],
      subscription: {
        start: data.newRegistration ? today : data.subscription.start,
        end: data.newRegistration ? nextYear : data.subscription.end,
      },
    }
  )
}