import { all, put, spawn, takeLatest } from 'redux-saga/effects'
import { actions } from './reducer'
import { navigate } from '@reach/router'

function* initApp({ payload }) {
  yield put(actions.setRoutes(payload))
  yield put({ type: 'auth/REFRESH' })
  yield put({ type: 'LOAD_PUBLISHERS' })
  //yield put({ type: 'LOAD_PRICES' })
  yield put({ type: 'LOAD_USER_GROUPS' })
}

function* redirect({ payload }) {
  const { param } = payload
  yield spawn(navigate, param)
}

export default function* appSaga() {
  yield all([
    yield takeLatest('INIT_APP', initApp),
    yield takeLatest('REDIRECT', redirect),
  ])
}
