import React, { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FlowContext } from "@stackhouseos/flow-client";
import { Spin } from "antd";

const Saga = React.memo(({ name, value, param }) => {
  const dispatch = useDispatch();
  const { flowName, reducerName } = useContext(FlowContext);

  useEffect(() => {
    if (name)
      dispatch({
        type: name,
        payload: {
          flowName,
          reducerName,
          value,
          param,
        },
      });
  }, [dispatch, name, flowName, reducerName, value, param]);

  return (
    <div style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      <Spin />
    </div>
  );
});

export default Saga;
