import { createSelector } from 'reselect'
import _prop from 'lodash/fp/prop'

const selectMenuState = _prop('menu')

export const getSelectedGroups = createSelector(selectMenuState, _prop('selectedGroups'))

export const getSelectedKeywords = createSelector(selectMenuState, _prop('selectedKeywords'))

export const getSelectedPublishers = createSelector(selectMenuState, _prop('selectedPublishers'))
