import { all, takeLatest, call, put, spawn, select, debounce } from 'redux-saga/effects'
import app, { mongo } from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { message } from 'antd'
import { navigate } from '@reach/router'
import { DASHBOARD } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/constants'
import { feedPaginationActions } from '@stackhouse/rassegnastampa-core/lib/containers/Pagination/actions'
import { feedPaginationSelectors as selectors } from '@stackhouse/rassegnastampa-core/lib/containers/Pagination/selectors'
import { withLoading } from '@stackhouse/rassegnastampa-core/lib/containers/Loading/withSaga'
import { getUserRole } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/selectors'
import { LOGOUT } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/actions'
import { GET_FEEDS, GET_FEED } from './constants'
import { actions } from './reducer'
import { actions as actionsmenu } from '../SidebarMenu/reducer'
import { getSelectedPublishers, getSelectedKeywords, getSelectedGroups } from '../SidebarMenu/selectors'
import { getSearchValue } from './selectors'
import he from 'he'
import { stripHtml } from '@stackhouse/rassegnastampa-core/lib/utils/helpers'
import { getLastTime } from './selectors'

const BSON = require("bson");

function* getFeeds(timeCheck) {
  if (window.location.pathname !== '/') return

  if (timeCheck) {
    const now = new Date().getTime()
    const lastTime = yield select(getLastTime)
    if (now - lastTime < 60000) return
  }

  const { current, pageSize } = yield select(selectors.getPagination)

  const role = yield select(getUserRole)
  const selectedGroups = yield select(getSelectedGroups)
  const selectedPublishers = yield select(getSelectedPublishers)
  const selectedKeywords = yield select(getSelectedKeywords)
  const searchValue = yield select(getSearchValue)

  // è necessario selezionare almeno un gruppo
  if (role === 'user' && (!selectedGroups || !selectedGroups.length))
    return

  const keywordsSelected = selectedKeywords.map(e => {
    const keyClean = e.replace('keywords-', '')
    return keyClean//.indexOf(' ') !== -1 ? `"${keyClean}"` : keyClean
    // al momento tolto perchè creava 2 apici doppi e non funzionava la ricerca
    // return keyClean.indexOf(' ') !== -1 ? `"${keyClean}"` : keyClean
  })

  
  try {

    const { total, results } = yield app.currentUser.functions.findFeed(
      selectedPublishers.map(e => e.replace('publishers-', '')),
      keywordsSelected,
      role === 'master' && (!selectedGroups || !selectedGroups.length) ? [] : selectedGroups.map(e => e.replace('groups-', '')),
      current,
      pageSize,
      searchValue
    )
    yield put(actions.setItems({
      results: results.map(e => ({
        ...e,
        title: he.decode(stripHtml(e.title)),
        body: he.decode(stripHtml(e.body)),
        _id: e._id.toString()
      }))
    }))

    yield put(feedPaginationActions.setPaginationProps({
      lastPage: current,
      'pagination.total': total
    }))
  } catch (error) {
    console.error(error)
  }
}

function* getFeed({ payload }) {
  const collection = yield call([mongo, 'collection'], 'feeds')

  try {
    const result = yield call([collection, 'findOne'], {
      _id: new BSON.ObjectId(payload.toString())
    })
    yield put(actions.setFeed({
      ...result,
      _id: result._id.toString(),
      title: he.decode(stripHtml(result.title)),
      body: he.decode(stripHtml(result.body)),
    }))
  } catch (error) {
    console.log('error', error)
    yield spawn(navigate, DASHBOARD)
  }
}

function* changedFilter(c) {
  yield put(feedPaginationActions.setPage(1))
}

function* resetPagination() {
  yield put(feedPaginationActions.resetPagination())
}

function* feedsRemove({ payload }) {
  const { id, articleId } = payload
  try {
    const result = yield app.currentUser.functions.removeFeed({ id, articleId })

    if (!result.modifiedCount) {
      message.success('L\'articolo non è stato eliminato');
    }

    if (result.modifiedCount) {
      message.success('L\'articolo è stato eliminato');
      yield put(actions.removeFeed({ id }))

      yield spawn(navigate, DASHBOARD)
    }

  } catch (error) {
    console.log('error', error)
  }
}

export default function* appSaga() {
  yield all([
    yield takeLatest(
      [
        feedPaginationActions.setPagination.toString(),
        feedPaginationActions.setPage.toString()
      ],
      withLoading('feeds', () => getFeeds(false))),
    yield takeLatest(
      [GET_FEEDS], withLoading('feeds', () => getFeeds(true))),
    yield takeLatest(
      GET_FEED,
      withLoading('articleDetail', getFeed),
    ),
    yield debounce(500,
      [
        actionsmenu.toggleGroups.toString(),
        actionsmenu.togglePublisher.toString(),
        actionsmenu.toggleKeyword.toString(),
        actions.setSearch.toString(),
        'FEEDS_RELOAD',
      ], changedFilter),
    yield takeLatest(LOGOUT, resetPagination),
    yield takeLatest('FEEDS_REMOVE', withLoading('articleDetail', feedsRemove))
  ])
}
