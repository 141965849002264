import { createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'
import { withPaginationReducer } from '@stackhouse/rassegnastampa-core/lib/containers/Pagination/withReducer'

export const initialState = {
  viewType: 'list',
  items: [],
  feed: null,
  lastTimeLoad: 0
}

const slice = createSlice({
  name: 'feeds',
  initialState,
  reducers: {
    setViewType: (state, action) => {
      state.viewType = action.payload
    },
    setItems: (state, action) => {
      state.items = action.payload.results
      state.groups = action.payload.groups
      state.lastTimeLoad = new Date().getTime()
    },
    setFeed: (state, action) => {
      state.feed = action.payload
    },
    removeFeed: (state, action) => {
      state.items = state.items.filter(e => e.id !== action.payload.id)
    },
    setSearch: (state, action) => {
      state.search = action.payload
    },
    resetFeed: (state) => {
      state.feed = null
    }
  },
  extraReducers: {
    '@box/feeds/update': (state, action) => _set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
slice.reducer = withPaginationReducer(slice.reducer, slice.name, initialState)
export default slice
