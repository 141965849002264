import { createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'
import { includes } from 'lodash'

export const initialState = {
  selectedGroups: [],
  selectedKeywords: [],
  selectedPublishers: []
}

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleGroups: (state, { payload: selectedId }) => {
      if (selectedId)
        state.selectedGroups = [selectedId]
    },
    toggleKeyword: (state, { payload: selectedId }) => {
      if (includes(state.selectedKeywords, selectedId)) {
        _set(state, 'selectedKeywords', state.selectedKeywords.filter(id => id !== selectedId))
      } else {
        state.selectedKeywords.push(selectedId)
      }
    },
    togglePublisher: (state, { payload: selectedId }) => {
      if (includes(state.selectedPublishers, selectedId)) {
        _set(state, 'selectedPublishers', state.selectedPublishers.filter(id => id !== selectedId))
      } else {
        state.selectedPublishers.push(selectedId)
      }
    },
  }
})

export const actions = slice.actions
export default slice
