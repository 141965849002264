import { combineReducers } from '@reduxjs/toolkit'
import auth from '@stackhouse/rassegnastampa-core/lib/containers/Auth/reducer'
import loading from '@stackhouse/rassegnastampa-core/lib/containers/Loading/reducer'
import stepperState from '@stackhouse/rassegnastampa-core/lib/containers/StepperState/reducer'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { flowReducer } from '@stackhouseos/flow-client'
import feeds from '../containers/Feeds/reducer'

const context = require.context('../', true, /containers\/[\s\S]*?\/reducer.js$/)
const reducers = context.keys().map(filename => context(filename).default)

const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['error', 'temp', 'role']
}

const feedPersistConfig = {
  key: 'feeds',
  storage: storage,
  whitelist: ['viewType']
}

export default combineReducers({
  ...reducers.reduce((acc, inc) => ({ ...acc, [inc.name]: inc.reducer }), {}),
  auth: persistReducer(authPersistConfig, auth.reducer),
  feeds: persistReducer(feedPersistConfig, feeds.reducer),
  loading: loading.reducer,
  stepperState: stepperState.reducer,
  flow: flowReducer
})
