export const HOME = '/'
export const DASHBOARD = '/'


// Dashboard child routes
export const REGISTRATION = '/registration'
export const FEEDS = '/feeds'
export const UPLOAD = ''
export const UPLOAD_CREATE = 'create'
export const DETAIL_PAGE = 'feeds/:id'
export const USERS_MANAGEMENT = 'users'
export const USER_DETAIL = 'users/:userId'
export const USER_CREATE = 'orders/create'
export const ACCOUNT = 'account'
export const ORDERS = 'orders'
export const ORDERS_SUCCESS = 'orders/success'
export const ORDERS_CANCEL = 'orders/cancel'
export const CHANGE_PASSWORD = 'change-password'

export const routesForBreadcrumbs = {
  dashboard: {
    name: 'Rassegna Stampa'
  },
  feeds: {
    name: 'Articoli',
    reducer: 'feeds.feed',
    param: 'id',
    valuePath: 'title'
  },
  users: {
    name: 'Utenti',
    param: 'userId',
    reducer: 'users.edited',
    valuePath: 'name'
  },
  userId: {
    name: 'Utente'
  },
  orders: {
    name: 'Piani e licenze'
  },
  account: {
    name: 'Gestione Account'
  },
  upload: {
    name: 'Carica Giornale'
  },
  article: {
    name: 'Articolo'
  },
  "change-password": {
    name: 'Cambio Password'
  }
}