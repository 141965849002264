import React, { lazy } from 'react'
import { Router, Redirect } from '@reach/router'
import { CHANGEPASSWORD } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/constants'
import {
  ACCOUNT,
  DETAIL_PAGE,
} from '../../containers/App/routes'

const NotFound = lazy(() => import('@stackhouse/rassegnastampa-core/lib/pages/NotFound'))
const ChangePassword = lazy(() => import('@stackhouse/rassegnastampa-core/lib/pages/ChangePassword'))
const Account = lazy(() => import('../Account'))
const ArticleDetail = lazy(() => import('../ArticleDetail'))
const Feed = lazy(() => import('../Feed'))

export default () => {
  return (
    <Router>
      <Account path={ACCOUNT} />
      <ChangePassword path={CHANGEPASSWORD} />
      <ArticleDetail path={DETAIL_PAGE} />
      <Feed path='/' />
      <Redirect from="feeds" to="/" noThrow />
      <NotFound default />
    </Router>
  )
}
