import { all, put, call, takeLatest, select } from 'redux-saga/effects'
import { mongo } from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { withLoading } from '@stackhouse/rassegnastampa-core/lib/containers/Loading/withSaga'
import { actions } from './reducer'
import BSON from 'bson'
import { getUserId } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/selectors'
import app from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { getLastTime } from './selectors'

function* load() {
  const now = new Date().getTime()
  const lastTime = yield select(getLastTime)
  if (now - lastTime < 60000) return

  const masterId = yield select(getUserId)
  const collection = yield call([mongo, 'collection'], 'invitations')
  const results = yield call([collection, 'find'], { role: 'user', masterId })
  yield put(actions.setItems({ results }))
}

function* resendToken({ payload }) {
    const { _id } = payload
    try {
        yield app.currentUser.functions.resendMailInvitation(_id.toString())   
    } catch (error) {
        console.error(error)
    }
}

function* deleteUser({ payload }) {
    const collection = yield call([mongo, 'collection'], 'invitations')
    const { _id } = payload
  
    try {
        const result = yield call([collection, 'deleteOne'], { _id: new BSON.ObjectId(_id.toString()) })
        yield put(actions.removeItem({ _id }))

      } catch (error) {
        console.error(error)
      }
}

export default function* appSaga() {
  yield all([
    yield takeLatest('LOAD_USERS_PENDING', withLoading('userList',load)),
    yield takeLatest('PENDING_USER_RESEND', resendToken),
    yield takeLatest('PENDING_USER_DELETE', deleteUser),
  ])
}
