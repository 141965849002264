import { createSlice } from '@reduxjs/toolkit'
import { set } from 'lodash'
import { withPaginationReducer } from '@stackhouse/rassegnastampa-core/lib/containers/Pagination/withReducer'

export const initialState = {
  items: [],
  loading: false,
  groupsByJob: [],
  lastTimeLoad: 0
}

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setEditedUser: (state, action) => {
      state.edited = action.payload
    },
    setItems: (state, { payload }) => {
      state.items = payload.results.map(e => ({ ...e, _id: e._id.toString() }))
      state.lastTimeLoad = new Date().getTime()
    },
    clearEdited: (state) => {
      state.edited = undefined
    },
    addTemp: (state, { payload }) => {
      state.temp = payload
    },
    updRepeatInterval: (state, { payload }) => {
      set(state, 'edited.nextRunAt', payload)
    },
    setGroupsByJob: (state, { payload }) => {
      state.groupsByJob = payload
    },
    setNextRunAt: (state, { payload }) => {
      state.nextRunAt = payload.nextRunAt
      state.repeatInterval = payload.repeatInterval
    }
  },
  extraReducers: {
    '@box/users/update': (state, action) => set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
slice.reducer = withPaginationReducer(slice.reducer, slice.name, initialState)
export default slice
