import { all, put, call, takeLatest } from 'redux-saga/effects'
import { mongo } from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { actions } from './reducer'
import { actions as actionsAuth } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/reducer'


function* load() {
  const collection = yield call([mongo, 'collection'], 'publishers')
  const results = yield call([collection, 'find'], {})
  yield put(actions.setItems({ results }))
}

export default function* appSaga() {
  yield all([
    yield takeLatest([actionsAuth.setLoginData.toString(), 'LOAD_PUBLISHERS'], load),
  ])
}
