import { createSlice } from '@reduxjs/toolkit'
import { set, unset } from 'lodash'
import { regexEmail } from '@stackhouse/rassegnastampa-core/lib/utils/constants'

export const initialState = {
  plan: 'basic',
  keywords: [],
  publishers: [],
  users: []
}

const slice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    addUsers: (state, { payload }) => {
      state.users = [...state.users, payload]
    },
    removeUser: (state, { payload }) => {
      const { id } = payload
      state.users = state.users.filter(e => e.id !== id)
    },
    editUserIncomplete: (state, { payload }) => {
      const index = payload.index
      const usersNew = state.users
      const emailControl = usersNew[index].email ? regexEmail.test(usersNew[index].email) : false
      const requiredFields = [usersNew[index].name, emailControl, usersNew[index].keywords.length > 0]
      const incomplete = requiredFields.some(e => !e)
      state.users[index] = {...state.users[index], incomplete }
    },
    setPlan: (state, { payload }) => {
      const { id } = payload 
      state.plan =  id
      const users = state.users
      const maxKeywordsBasic = process.env.REACT_APP_KEYWORDS_BASIC
      if( id === 'basic') {
        const newKeywords = state.keywords.slice(0, maxKeywordsBasic)
        state.keywords = newKeywords 

        if(users.length) { // clean keywords users
          state.users = users.map(e => ({ ...e, keywords: e.keywords.filter(inc => newKeywords.includes(inc)) }))
        }
      }
    },
    setOrderId: (state, { payload }) => {
      state.orderId = payload.insertedId
    },
    setKeywords: (state, { payload }) => {
      const { value: keywords } = payload
      const users = state.users

      if(users.length) { // clean keywords users
        state.users = users.map(e => ({ ...e, keywords: e.keywords.filter(inc => keywords.includes(inc)) }))
      }
    },
  },
  extraReducers: {
    '@box/registration/update': (state, action) => set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
export default slice
