import { createSelector } from 'reselect'
import _prop from 'lodash/fp/prop'
import { getPrices, usersPrice, planPrice } from '../Prices/selectors'

const selectState = _prop('registration')
const keywords = createSelector(selectState, _prop('keywords'))
const publishers = createSelector(selectState, _prop('publishers'))
const users = createSelector(selectState, _prop('users'))
const plan = createSelector(selectState, _prop('plan'))

export const getKeywords = createSelector(keywords, (val) => val || [])
export const getPublishers = createSelector(publishers, (val) => val || [])
export const getUsers = createSelector(users, (val) => val || [])
export const getAllUsers = createSelector(users, (val) => val || [])
export const getPlan = createSelector(plan, (val) => val)

const userPriceCart = createSelector(
    getUsers,
    getPrices,
    (users, prices) => usersPrice(users, prices)
)

const appPriceCart = createSelector(
    getPlan,
    getPrices,
    (plan, prices) => planPrice({ plan }, prices)
)

export const getPriceRegistration = createSelector(
    userPriceCart,
    appPriceCart,
    (userPrice, panPrice) => (userPrice + panPrice).toFixed(2)
)
