import { createSelector } from 'reselect'
import _prop from 'lodash/fp/prop'
import _get from 'lodash/get'
import _filter from 'lodash/filter'
import { getUserEmail, getUserSubscription, getUserPlan } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/selectors'
import { getUsers } from '../Users/selectors'
import { getPendingUsers } from '../Invitations/selectors'
import { today } from './utils'
import { getPrices, usersPrice, plansPrices, promo } from '../Prices/selectors'
const userUpTo = parseInt(process.env.REACT_APP_USER_UPTO_NUMB)

const selectOrdersState = _prop('orders')
const oneDay = 86400000

const days_from_expired = {
  '3': 30,
  '6': 30,
  '12': 90
}

export const getOrders = createSelector(
  selectOrdersState,
  (Orders) => _get(Orders, ['items'], [])
)

const getAccountUsers = createSelector(
  getUsers,
  getPendingUsers,
  (users, pending) => [...users, ...pending]
)

export const getUsersExpired = createSelector(
  getAccountUsers,
  users => {
    const usersSubscription = users.filter(e => _get(e, 'subscription.end', false))
    const expired = usersSubscription.filter(e => e.subscription.end < today)

    return expired
  }
)

export const getUsersRenawal = createSelector(
  getAccountUsers,
  getUsersExpired,
  (users, expired) => {
    const usersSubscription = users.filter(e => _get(e, 'subscription.end', false))

    const toExpire = usersSubscription.filter(e => {
      const lastPeriod = e.subscription.end - oneDay * days_from_expired[e.subscriptionInterval] // periodo dalla scadenza a ritroso
      return today > lastPeriod
    })

    return toExpire.filter(i => expired.findIndex(e => e.id === i.id) === -1)
  }
)

export const getOrdersHistory = createSelector(
  getOrders,
  items => items
)


export const getMyUserPending = createSelector(
  getUserEmail,
  getUserSubscription,
  getUserPlan,
  (email, subscription, plan) => {
    const subscriptionEnd = _get(subscription, 'end', 0)
    const lastPeriod = subscriptionEnd - oneDay * days_from_expired['12']
    const control = today > lastPeriod

    return control ? {
      id: 'plan',
      status: 'active',
      role: 'master',
      name: `Piano ${plan}`,
      email,
      subscriptionInterval: "12",
      subscription,
      plan
    } : null
  }
)

// CART

export const getCartUsers = createSelector(
  selectOrdersState,
  (Orders) => _get(Orders, ['cart', 'users'], [])
)

export const getCartUsersNew = createSelector(
  getCartUsers,
  (users) => _filter(users, { newRegistration: true })
)

export const getCartPlans = createSelector(
  selectOrdersState,
  (Orders) => _get(Orders, ['cart', 'plans'], [])
)

export const getCartItems = createSelector(
  getCartUsers,
  getCartPlans,
  (users, plans) => [...users, ...plans]
)

export const getPromoApp = createSelector( // controlla se la promo è attiva
  getCartUsers,
  selected => {
    return promo(selected.length)
  }
)

const userPriceCart = createSelector(
  getCartUsers,
  getPrices,
  (users, prices) => {
    return usersPrice(users, prices)
  }
)

const appPriceCart = createSelector(
  getCartPlans,
  getPrices,
  (plans, prices) => {
    return plansPrices(plans, prices)
  }
)

export const getPriceCart = createSelector(
  userPriceCart,
  appPriceCart,
  (userPrice, panPrice) => {
    return (userPrice + panPrice).toFixed(2)
  }
)