import { createSlice } from '@reduxjs/toolkit'
import { regexEmail } from '@stackhouse/rassegnastampa-core/lib/utils/constants'
import _set from 'lodash/set'
import _omit from 'lodash/omit'
import _filter from 'lodash/filter'

export const initialState = {
  items: [],
  cart: {
    plans: [],
    users: [],
  }
}

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.items = payload.results
    },
    editCartUser: (state, { payload }) => {
      const selected = state.cart.users
      const data = _omit(payload, 'id')
      const index = selected.findIndex(e => e.id === payload.id)

      if(index !== -1) state.cart.users[index] = { ...selected[index], ...data }
    },
    editCartUserIncomplete: (state, { payload }) => {
      const index = payload.index
      const usersNew = _filter(state.cart.users, { newRegistration: true })
      if(usersNew) {
        const emailControl = usersNew[index].email ? regexEmail.test(usersNew[index].email) : false
        const requiredFields = [usersNew[index].name, emailControl, usersNew[index].keywords.length > 0]
        const incomplete = requiredFields.some(e => !e)
        state.cart.users[index] = {...state.cart.users[index], incomplete }
      }
    },
    addCartUsers: (state, { payload }) => {
      const selected = state.cart.users
      const control = selected.findIndex(e => e.id === payload.id) !== -1
      state.cart.users = control ? selected.filter(e => e.id !== payload.id) : [...selected, payload]
    },
    removeCartItem: (state, { payload }) => {
      const { id, role } = payload
      if(role === 'user') state.cart.users = state.cart.users.filter(e => e.id !== id)
      if(role === 'master') state.cart.plans = state.cart.plans.filter(e => e.id !== id)
    },
    addCartPlans: (state, { payload }) => {
      const selected = state.cart.plans
      const control = state.cart.plans.findIndex(e => e.id === payload.id) !== -1
      state.cart.plans = control ? selected.filter(e => e.id !== payload.id) : [...selected, payload]
    },
    clearCart: (state, { payload }) => {
      state.cart.plans = []
      state.cart.users = []
    },
  },
  extraReducers: {
    '@box/orders/update': (state, action) => _set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
export default slice
