import React from 'react'
import { padStart } from 'lodash'
import Text from '@stackhouse/rassegnastampa-core/lib/components/Text'
import { StepHeaderContainer } from '../styled'

const StepHeader = ({ stepNumber, message, warningMessage }) => {
  return (
    <StepHeaderContainer>
      <div className="main-heading">
        <Text type="primary" gutterRight={8} size="extraLarge" lineHeight={1.2}>
          {padStart(stepNumber, 2, '0')}.
        </Text>
        <Text type="textSpecial" size="extraLarge" lineHeight={1.2}>{message}</Text>
      </div>
      {warningMessage && (
        <Text block gutterTop={24}>
          <Text type="secondary" gutterRight={4}>ATTENZIONE!</Text>
          <Text type="textLight">{warningMessage}</Text>
        </Text>
      )}
    </StepHeaderContainer>
  )
}

export default React.memo(StepHeader)

