import { createSelector } from 'reselect'
import _prop from 'lodash/fp/prop'
import _keyBy from 'lodash/fp/keyBy'

const selectFeedState = _prop('publishers')

const getItems = createSelector(selectFeedState, _prop('items'))

export const publishersPublic = createSelector(
  getItems,
  items => items.filter(e => !e.hidden)
)

export const getPublishersByKey = createSelector(
  getItems,
  (feeds) => _keyBy('key')(feeds)
)
