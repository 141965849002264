import { all, put, call, takeLatest, spawn, select } from 'redux-saga/effects'
import { mongo } from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { createAction } from '@reduxjs/toolkit'
import { navigate } from '@reach/router'
import { message } from 'antd'
import { withLoading } from '@stackhouse/rassegnastampa-core/lib/containers/Loading/withSaga'
import app from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { checkoutStripe } from '@stackhouse/rassegnastampa-core/lib/utils/stripe'
import { actions as actionsAuth } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/reducer'
import { getCartItems, getCartUsersNew } from './selectors'
import { actions } from './reducer'
import { ORDERS, USER_CREATE } from '../App/routes'
import { generateModelPlan, generateModelUser } from './utils'
import { getUserRole } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/selectors'

export const statusPayment = {
  pending: 'Da pagare',
  complete: 'Pagato',
  expired: 'Scaduto',
  progress: 'Paga ora'
}


function* load() {
  const collection = yield call([mongo, 'collection'], 'orders')
  // TODO dovrebbe farlo solo per il master
  const role = yield select(getUserRole)
  if (role !== 'master') return

  try {
    const results = yield call([collection, 'find'], {})
    const resultsExplode = results.reduce((acc, inc) => { // SERVE SOLAMENTE PER VISUALIZZARE MEGLIO GLI ORDINI NELLA TABELL
      const multipleOrder = inc.items.length > 1;

      const itemInfo = {
        role: inc.items[0].role,
        subscriptionInterval: inc.items[0].subscriptionInterval,
        subscription: inc.items[0].subscription,
        userId: inc.items[0].id,
        plan: inc.items[0].plan,
        explode: true
      }

      const itemOrder = multipleOrder ? [] : itemInfo;
      const itemsOrder = multipleOrder ? inc.items : null;

      return ([...acc, { ...inc, items: itemsOrder, ...itemOrder }])
    }, [])
    yield put(actions.setItems({ results: resultsExplode }))
  } catch (error) {
    console.log('error', error)
  }
}



function* orderCart() {
  const cartItems = yield select(getCartItems)
  const orderData = cartItems.reduce((acc, inc) => {
    const model = inc.role === 'master' ? generateModelPlan(inc) : generateModelUser(inc)
    return ([...acc, model])
  }, [])

  const incomplete = orderData.some(e => e.incomplete)

  if(incomplete) {
    message.error('Completa i campi mancanti prima di procedere')
    yield spawn(navigate, `/${USER_CREATE}`)
    return;
  }

  try {
    const { insertedId } = yield app.currentUser.functions.createOrder(orderData)
    yield checkoutStripe(insertedId.toString())
    yield put(actions.clearCart())
    yield spawn(navigate, `/${ORDERS}`)
    yield put(createAction('LOAD_ORDERS')())

  } catch (error) {
    console.error(error)
  }
}


function* checkout({ payload }) {
  const { id } = payload

  //TEMP TEST COMPLETAMENTO ORDINE
  //yield app.currentUser.functions.completeOrder(id)

  // const collection = yield call([mongo, 'collection'], 'orders')
  // const result = yield call([collection, 'updateOne'], { _id: new BSON.ObjectId(id.toString()) }, { $set: { status: 'paid' } })
  // yield put(createAction('LOAD_ORDERS')())

  try {
    yield checkoutStripe(id)
    yield put(createAction('LOAD_ORDERS')())
  } catch (error) {
    console.error(error)
  }
}

export default function* appSaga() {
  yield all([
    yield takeLatest([actionsAuth.setLoginData.toString(), 'LOAD_ORDERS'], load),
    yield takeLatest('ORDER_CART', withLoading('orders', orderCart)),
    yield takeLatest('ORDER_CHECKOUT', withLoading('orders', checkout))
  ])
}
