import styled from 'styled-components'
import { media } from '@stackhouse/rassegnastampa-core/lib/utils/theme'

export const RightPanel = styled.div`
  width: 35%;
  max-width: 480px;
  border-top-left-radius: 60px;
  background-color: ${({ theme }) => theme.colors.primary};
  height: 100vh;
  display: flex;
  flex-direction: column;

  .progress-header {
    line-height: 1.2;
    margin: 60px 60px 10px 60px;

    ${media.maxWidth('lg')`
      & {
        margin: 30px 48px 10px 48px;
      }
    `}
  }
`

export const ProgressStatusCircle = styled.div`
  position: absolute;
  transform: translate(-50%, -30%);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isCurrent, isCompleted, theme }) =>
    isCurrent ? theme.colors.secondary :
      isCompleted ? theme.colors.primary :
        theme.colors.white};
  color: ${({ isCurrent, theme }) => isCurrent ? theme.colors.white : theme.colors.textLight};
  border-style: solid;
  border-width: 1px;
  border-color: ${({ isCurrent, isCompleted, theme }) =>
    isCurrent ? theme.colors.secondary :
      isCompleted ? theme.colors.primary :
        theme.colors.textLight};
`

export const StepDescriptionContainer = styled.div`
  width: 100%;
  .step-description-text {
    padding: 0 60px;
    opacity: ${({ isCurrent }) => isCurrent ? 1 : 0.5};

    ${media.maxWidth('lg')`
      & {
        padding: 0 48px;
      }
    `}
  }
`

export const StepsDescriptionsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

export const PriceBoxContainer = styled.div`
  padding: 10px 60px 80px 60px;
  .price-box {
    background-color: ${({ theme }) => theme.colors.primaryLight};
    border-radius: 20px;
    padding: 14px 28px;
  }

  ${media.maxWidth('lg')`
    & {
      padding: 10px 48px 30px 48px;
    }
  `}
`

export const StepHeaderContainer = styled.div`
  ${media.maxWidth('sm')`
    margin-bottom: 32px;
    .main-heading {
      span {
        font-size: 28px;
      }
    }
  `}
`