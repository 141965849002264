import { createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'

export const initialState = {
  items: [],
  loading: false,
  lastTimeLoad: 0
}

const slice = createSlice({
  name: 'invitations',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.items = payload.results
      state.lastTimeLoad = new Date().getTime()
    },
    removeItem: (state, { payload }) => {
      state.items = state.items.filter(e => e._id !== payload._id)
    }
  },
  extraReducers: {
    '@box/invitations/update': (state, action) => _set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
export default slice