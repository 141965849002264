import styled from 'styled-components'
import { media } from '@stackhouse/rassegnastampa-core/lib/utils/theme'

export const StartPageContainer = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
`

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  transform: translateX(50%);
`

export const PriceBox = styled.div`
  margin: 40px 0;
  h2 {
    line-height: 1.2;
  }
`

export const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  min-height: inherit;
  padding: 120px 30px;
  .cta-box {
    padding-top: 20px;
  }
  .ant-space {
    display: flex;
    flex-direction: column;
  }

  ${media.minWidth('md')`
    padding: 120px;
    .cta-box {
      padding-top: 0;
      width: 80%;
      h1 {
        display: inline-block;
      }
    }
  `}

  ${media.minWidth('lg')`
    .cta-box {
      width: 70%;
      h1 {
        display: block;
      }
      span {
        margin-bottom: 120px;
      }
    }
    .ant-space {
      display: inline-flex;
      flex-direction: row;
    }
  `}
`

export const Header = styled.div`
  position: absolute;
  top: 32px;
  left: 120px;

  ${media.maxWidth('md')`
    left: 30px;
  `}
`

// TODO: move to components
export const RightPanel = styled.div`
  width: 35%;
  max-width: 480px;
  border-top-left-radius: 60px;
  background-color: ${({ theme }) => theme.colors.primary};
  min-height: 100vh;
`
