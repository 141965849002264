import React, { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Menu from '@stackhouse/rassegnastampa-core/lib/components/Menu'
import { getUserRole, getUserKeywords, getPublisherName } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/selectors'

import { getMenuConfig } from './config'
import { getSelectedPublishers, getSelectedKeywords, getSelectedGroups } from './selectors'
import { actions } from './reducer'
import { getPublishersByKey } from '../Publishers/selectors'
import { getUserGroupsByJob } from '../Users/selectors'
import { navigate } from '@reach/router'

const actionsMap = {
  groups: actions.toggleGroups,
  keywords: actions.toggleKeyword,
  publishers: actions.togglePublisher
}

const SidebarMenu = ({ location, logo }) => {
  const dispatch = useDispatch()

  const userRole = useSelector(getUserRole)
  const userGroupsByJob = useSelector(getUserGroupsByJob)
  const userKeywords = useSelector(getUserKeywords)
  const userPublishers = useSelector(getPublisherName(getPublishersByKey))
  const selectedGroups = useSelector(getSelectedGroups)
  const selectedKeywords = useSelector(getSelectedKeywords)
  const selectedPublishers = useSelector(getSelectedPublishers)

  const menuItems = useMemo(
    () => getMenuConfig(userKeywords, userPublishers, userGroupsByJob, userRole),
    [userPublishers, userKeywords, userGroupsByJob, userRole]
  )

  const onItemClick = ({ keyPath, ...a }) => {
    const [key, rootKey] = keyPath
    const action = actionsMap[rootKey]

    if (action) {
      // porto sempre alla homepage, se clicco le checkbox o radio (filtri)
      navigate('/')
      dispatch(action(key))
    }
  }

  return (
    <Menu
      location={location}
      items={menuItems}
      selectedKeys={[...selectedGroups, ...selectedKeywords, ...selectedPublishers]}
      onItemClick={onItemClick}
      logo={logo}
    />
  )
}

export default SidebarMenu
