import React, { Suspense, useMemo, useEffect, lazy, useState } from 'react'
import { useLocation, } from '@reach/router'
import Page from '@stackhouse/rassegnastampa-core/lib/components/Page'
import Loading from '@stackhouse/rassegnastampa-core/lib/components/Loading'
import { useSelector, useDispatch } from 'react-redux'
import { getLogoMaster, getUserMasterId, getUserRole } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/selectors'
import app from '@stackhouse/rassegnastampa-core/lib/utils/api'

import Menu from '../../containers/SidebarMenu'

import masterRoutes from './master'
import userRoutes from './user'

import { routesForBreadcrumbs } from '../../containers/App/routes'
import { Spin } from 'antd'

// const Deactived = lazy(() => import('@stackhouse/rassegnastampa-core/lib/pages/Deactived'))

const routerMap = {
  master: masterRoutes,
  user: userRoutes
}

export default () => {
  const [logo, setLogo] = useState()
  const location = useLocation()
  const dispatch = useDispatch()
  const userRole = useSelector(getUserRole)
  const masterId = useSelector(getUserMasterId)
  const logoMaster = useSelector(getLogoMaster)
  const RouterByRole = useMemo(() => routerMap[userRole], [userRole])

  useEffect(() => {
    dispatch({ type: 'INIT_APP', payload: { routes: routesForBreadcrumbs } })
  }, [dispatch])


  useEffect(() => {
    const run = async () => {
      if (userRole === 'user' && masterId) {
        const masterData = await app.currentUser.functions.getLogoMasterId(masterId)
        setLogo(masterData.urlLogo)
      }

      if (userRole === 'master') {
        setLogo(logoMaster)
      }
    }
    if (userRole)
      run()
  }, [userRole, masterId, logoMaster])

  if (!RouterByRole) return <Spin />

  return (
    <Page sidebarContent={<Menu location={location} />}>
      <Suspense fallback={<Loading />}>
        <RouterByRole />
      </Suspense>
    </Page>
  )
}
