import { createSelector } from 'reselect'
import _prop from 'lodash/fp/prop'
import { get } from 'lodash'
import moment from 'moment'

const selectUsersState = _prop('users')
const selectGroupsState = _prop('users.groupsByJob')
const selectTempState = _prop('users.temp')
const getFeeds = createSelector(selectUsersState, _prop('items'))
export const getLastTime = createSelector(selectUsersState, _prop('lastTimeLoad'))

export const getUsers = createSelector(
  getFeeds,
  (users) => users
)

export const getTempUserKeywords = createSelector(
  selectTempState,
  (temp) => {
    return get(temp, 'keywords', []).length
  }
)

export const getNextRunAt = createSelector(
  selectUsersState,
  (user) => user.nextRunAt || 0
)

export const getRepeatInterval = createSelector(
  selectUsersState,
  (user) => user.repeatInterval
)

export const getUsersNumb = createSelector(
  getUsers,
  (users) => users.length
)

export const getUserGroupsByJob = createSelector(
  selectGroupsState,
  (items = []) => {
    return [...items].filter(d => moment(d, 'YYYYMMDD').isValid()).sort().reverse().slice(0, 10)
  }
)