import { createSelector } from 'reselect'
import map from 'lodash/map'
import _sort from 'lodash/sortBy'
import _prop from 'lodash/fp/prop'
import _keyBy from 'lodash/fp/keyBy'
import _get from 'lodash/get'

const selectLoginState = _prop('auth')

export const getError = createSelector(selectLoginState, _prop('error'))

export const isUserLogged = createSelector(selectLoginState, _prop('isLoggedIn'))

export const getUserRole = createSelector(selectLoginState, _prop('role'))

export const getUserKeywords = createSelector(selectLoginState, _prop('keywords'))

export const getUserZones = createSelector(selectLoginState, _prop('zones'))

export const getAccountId = createSelector(selectLoginState, _prop('accountId'))

export const getUserId = createSelector(selectLoginState, _prop('userId'))

export const getUserStatus = createSelector(selectLoginState, _prop('status'))

export const getUserKey = createSelector(selectLoginState, _prop('key'))

export const getUserPublishers = createSelector(selectLoginState, _prop('publishers'))

export const getUserEmail = createSelector(selectLoginState, _prop('email'))

export const getUserPrice = createSelector(selectLoginState, _prop('price'))

export const getUsersCreated = createSelector(selectLoginState, _prop('createdAt'))

export const getNewsletter = createSelector(selectLoginState, _prop('newsletter'))

export const getRepeatInterval = createSelector(selectLoginState, _prop('repeatInterval'))

export const getUserSubscription = createSelector(selectLoginState, _prop('subscription'))

export const getUserSubscribersMax = createSelector(selectLoginState, _prop('subscribersMax'))

export const getUserKeywordsMax = createSelector(selectLoginState, _prop('keywordsMax'))

export const getUserPlan = createSelector(selectLoginState, _prop('plan'))

export const getUserMasterId = createSelector(selectLoginState, _prop('masterId'))

export const getLogoMaster = createSelector(selectLoginState, _prop('urlLogo'))

export const getPublisherName = getPublishersByKey => createSelector(
  getUserPublishers,
  getPublishersByKey,
  (publishers, publishersDict) => map(publishers, (item) => ({
    id: item,
    name: (publishersDict[item] && publishersDict[item].name) || '-'
  }))
)
