import { createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'

const pricesInitial = {
  'user-12-20': 15920,
  'user-6-20': 8000,
  'user-3-20': 5520,
  'user-3': 6900,
  'user-6': 10000,
  'rassegnastampa-pro': 53000,
  'rassegnastampa-basic': 43000,
  'user-12': 19900
}

export const initialState = {
  items: pricesInitial
}

const slice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.items = payload.results
    },
  },
  extraReducers: {
    '@box/prices/update': (state, action) => _set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
export default slice
