"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StyledCard = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _lodash = require("lodash");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  padding: ", ";\n  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);\n  width: ", ";\n  border-bottom-left-radius: 10px;\n  border-bottom-right-radius: 10px;\n  background-color: white;\n  ", "\n\n  ::after {\n    content: '';\n    position: absolute;\n    top: -8px;\n    left: 0;\n    right: 0;\n    height: 5px;\n    background-color: ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var StyledCard = _styledComponents["default"].div(_templateObject(), function (_ref) {
  var small = _ref.small;
  return small ? '24px' : '60px 40px';
}, function (_ref2) {
  var cardWidth = _ref2.cardWidth;
  return "".concat(cardWidth).concat((0, _lodash.isFinite)(cardWidth) ? 'px' : '');
}, function (_ref3) {
  var cardMargin = _ref3.cardMargin;
  return cardMargin && "\n    ".concat(cardMargin.top ? "margin-top: ".concat(cardMargin.top, "px;") : '', "\n    ").concat(cardMargin.right ? "margin-right: ".concat(cardMargin.right, "px;") : '', "\n    ").concat(cardMargin.bottom ? "margin-bottom: ".concat(cardMargin.bottom, "px;") : '', "\n    ").concat(cardMargin.left ? "margin-left: ".concat(cardMargin.left, "px;") : '', "\n  ");
}, function (_ref4) {
  var theme = _ref4.theme,
      border = _ref4.border;
  return border ? theme.colors.secondary : 'transparent';
});

exports.StyledCard = StyledCard;