import { all, put, call, spawn, takeLatest, select } from 'redux-saga/effects'
import app, { mongo } from '@stackhouse/rassegnastampa-core/lib/utils/api'
import { navigate } from '@reach/router'
import { createAction } from '@reduxjs/toolkit'
import { actions as actionAuth } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/reducer'
import { withLoading } from '@stackhouse/rassegnastampa-core/lib/containers/Loading/withSaga'
import { actions } from './reducer'
import { getLastTime, getUsers } from './selectors'
import { getPendingUsers } from '../Invitations/selectors'
import { actions as actionsmenu } from '../SidebarMenu/reducer'
import BSON from 'bson'
import { message } from 'antd'
import { compact, last } from 'lodash'
import { getUserId, getUserRole, getUserPlan, getUserSubscription } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/selectors'
import { USERS_MANAGEMENT } from '../App/routes'
import moment from 'moment'
import parser from 'cron-parser'
import { getUserKeywordsMax } from '@stackhouse/rassegnastampa-core/src/containers/Auth/selectors'

const limitBasic = parseInt(process.env.REACT_APP_KEYWORDS_BASIC)
const limitPro = parseInt(process.env.REACT_APP_KEYWORDS_PRO)

function* load() {
  const now = new Date().getTime()
  const lastTime = yield select(getLastTime)
  if (now - lastTime < 60000) return

  const masterId = yield select(getUserId)
  // TODO aggiungere paginazione
  const collection = yield call([mongo, 'collection'], 'users')
  const results = yield call([collection, 'find'], { role: 'user', masterId })
  yield put(actions.setItems({ results }))
}

function* getUserById({ payload }) {
  const collection = yield call([mongo, 'collection'], 'users')
  const result = yield call([collection, 'findOne'], { _id: new BSON.ObjectId(payload.userId.toString()) })
  yield put(actions.setEditedUser({ ...result, _id: result._id.toString() }))
}

function* saveUser({ payload }) {
  const masterId = yield select(getUserId)
  const userSubscription = yield select(getUserSubscription)

  const userRole = yield select(getUserRole)
  const collection = yield call([mongo, 'collection'], 'users')
  const { keywords, publishers, email, phone, name, repeatInterval, nextRunAt = 9999999999, _id } = payload.value

  const dataUpdate = {
    phone,
    repeatInterval,
    keywords: compact(keywords),
    publishers: compact(publishers),
  }

  const dataCreate = {
    email,
    phone,
    name,
    repeatInterval,
    nextRunAt,
    subscription: userSubscription,
    ...dataUpdate
  }

  try {
    if (_id) {
      const result = yield call([collection, 'updateOne'], { _id: new BSON.ObjectId(_id.toString()) }, { $set: dataUpdate })

      if (!result.modifiedCount) {
        alert('Errore, riprova')
      }

      message.success('Utente modificato');
      yield spawn(navigate, `/${USERS_MANAGEMENT}`)

    } else {

      if (userRole !== 'master') {
        throw new Error('Is not master user')
      }

      const res = yield app.currentUser.functions.addInvitation([dataCreate], masterId)
      if (res && res.name === "Error") {
        message.error(res.message);
      } else {
        message.success('Utente invitato con successo');
        yield put({ type: 'LOAD_INVITATIONS' })
        yield spawn(navigate, `/${USERS_MANAGEMENT}`)
      }
    }


  } catch (error) {
    console.log(error)
    alert(error.errorCode)
  }
}

function* loadUserkeywords() {
  const keywords = yield select(state => state.auth.keywords)
  const publishers = yield select(state => state.auth.publishers)

  yield put(actions.addTemp({ keywords, publishers }))
}

function* updateKeywordsPublishersUsers({ users, data, collection, userId, onSuccess }) {
  const usersResults = yield all(users.map(user => {
    const isDifferentKeywords = user.keywords.some(e => !data.keywords.includes(e))
    const isDifferentPublishers = user.publishers.some(e => !data.publishers.includes(e))

    const updateUser = isDifferentKeywords || isDifferentPublishers
    if (!updateUser) return null

    const newKeywords = user.keywords.filter(inc => data.keywords.includes(inc))
    const newPublishers = user.publishers.filter(inc => data.publishers.includes(inc))

    return call([collection, 'updateOne'], {
      _id: new BSON.ObjectId(user._id.toString()),
      masterId: userId.toString()
    }, { $set: { keywords: newKeywords, publishers: newPublishers } })

  }))


  const userCountModified = compact(usersResults).length

  const oneChangeMessage = 'E\' stato modificato un sottoscrittore'
  const multipleChangeMessage = `Sono stati modificati ${userCountModified} sottoscrittori`
  if (userCountModified) {
    message.success(userCountModified === 1 ? oneChangeMessage : multipleChangeMessage);
    yield put(createAction(onSuccess)())
  }
}

function* updateKeywordsPublishers({ payload }) {
  const { publishers, keywords } = payload.value
  const collection = yield call([mongo, 'collection'], 'users')
  const userId = yield select(state => state.auth.userId)
  const users = yield select(getUsers)
  const usersPending = yield select(getPendingUsers)
  const keywordsMax = (yield select(getUserKeywordsMax)) || 5

  const data = {
    keywords: compact(keywords),
    publishers: compact(publishers),
  }

  if (data.keywords.length > keywordsMax) return message.error(`Non puoi inserire più di ${keywordsMax} parole chiave`);
  //if (plan === 'pro' && data.keywords.length > limitPro) return message.error(`Non puoi inserire più di ${limitPro} parole chiave`);

  try {
    const result = yield call([collection, 'updateOne'], { _id: new BSON.ObjectId(userId.toString()) }, { $set: data })
    if (!result.modifiedCount) {
      message.error('Il tuo account non è stato modificato');
      // TRIGGER CAMBIAMENTO KEYWORDS E PUBLISHER
    } else {
      yield put(actionAuth.setKeywords(data))
      yield put(actionAuth.setPublishers(data))

      if (users.length) {
        yield updateKeywordsPublishersUsers({
          users,
          data,
          collection,
          userId,
          onSuccess: 'LOAD_USERS'
        })

      }

      if (usersPending.length) {
        const mongoInvitations = yield call([mongo, 'collection'], 'invitations')
        yield updateKeywordsPublishersUsers({
          users: usersPending,
          data,
          collection: mongoInvitations,
          userId,
          onSuccess: 'LOAD_USERS_PENDING'
        })
      }

      message.success('Il tuo account è stato modificato');
    }

  } catch (error) {
    console.log(error)
  }


}

function* loadUserGroups() {
  const userId = yield select(getUserId)
  // const role = yield select(getUserRole)
  // if (role !== 'user') return

  const collection = yield call([mongo, 'collection'], 'userJobs')
  const result = yield call([collection, 'findOne'], { _id: new BSON.ObjectId(userId.toString()) })
  yield put(actions.setNextRunAt(result))

  // commentato per piattaforma abbonamenti
  yield put(actions.setGroupsByJob(result.groupsByJob))
  const lastEl = last(result.groupsByJob)
  if (result.groupsByJob) yield put(actionsmenu.toggleGroups(`groups-${lastEl}`))

}

const formatDateToYYYYMMDD = date => parseInt(moment(date).utc().format('YYYYMMDD'))

function* updateInterval({ payload }) {
  const { value: repeatInterval } = payload
  const currentDate = moment().utc().toDate()
  const interval = parser.parseExpression(repeatInterval, { currentDate, tz: 'Europe/Rome' });
  yield put(actions.updRepeatInterval(formatDateToYYYYMMDD(interval.next().toDate())))
}

function* updateNewsletter({ payload }) {
  const { newsletter } = payload.value
  const userId = yield select(getUserId)
  const collection = yield call([mongo, 'collection'], 'users')

  try {

    const result = yield call([collection, 'updateOne'], { _id: new BSON.ObjectId(userId.toString()) }, { $set: { newsletter } })
    if (!result.modifiedCount) {
      message.error('Il tuo account non è stato modificato');
    } else {
      message.success('Il tuo account è stato modificato');
    }
  } catch (error) {
    console.log(error)
  }

}

export default function* appSaga() {
  yield all([
    yield takeLatest('LOAD_USERS', withLoading('usersList', load)),
    yield takeLatest('LOAD_USER', withLoading('userDetail', getUserById)),
    yield takeLatest('SAVE_USER', withLoading('userDetail', saveUser)),
    yield takeLatest('USER_UPD_INTERVAL', updateInterval),
    yield takeLatest('LOAD_TEMP_KEYWORDS', loadUserkeywords),
    yield takeLatest('SAVE_MYUSER_KEYWORDS', withLoading('account', updateKeywordsPublishers)),
    yield takeLatest('SAVE_MYUSER_NEWSLETTER', withLoading('account', updateNewsletter)),
    yield takeLatest('LOAD_USER_GROUPS', loadUserGroups),
  ])
}
