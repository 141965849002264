import React, { Suspense, lazy } from 'react'
import { Router } from '@reach/router'

import GlobalStyle from '@stackhouse/rassegnastampa-core/lib/utils/GlobalStyle'
import Loading from '@stackhouse/rassegnastampa-core/lib/components/Loading'
import PrivateRoute from '@stackhouse/rassegnastampa-core/lib/components/PrivateRoute'
import DefaultPages from '@stackhouse/rassegnastampa-core/lib/pages'
// import { REGISTRATION } from './containers/App/routes'
import Dashboard from './pages/Dashboard'
import './utils/initBox'
import Invite from './containers/Invite/invite'

// const Registration = lazy(() => import('./pages/Registration'))

const App = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Router>
        {DefaultPages}
        {/* <Registration path={REGISTRATION} /> */}
        <Invite path={'/invite/:token'} />
        <PrivateRoute as={Dashboard} path={'/*'} />
      </Router>
      <GlobalStyle />
    </Suspense>
  )
}

export default App
