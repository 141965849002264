import { createSelector } from 'reselect'
import _prop from 'lodash/fp/prop'

const selectUsersState = _prop('invitations')
const getFeeds = createSelector(selectUsersState, _prop('items'))
export const getLastTime = createSelector(selectUsersState, _prop('lastTimeLoad'))

export const getPendingUsers = createSelector(
  getFeeds,
  (users) => users
)
