import { createSlice } from '@reduxjs/toolkit'
import _set from 'lodash/set'

export const initialState = {
  items: [],
}

const slice = createSlice({
  name: 'publishers',
  initialState,
  reducers: {
    setItems: (state, { payload }) => {
      state.items = payload.results
    }
  },
  extraReducers: {
    '@box/publishers/update': (state, action) => _set(state, action.payload.id, action.payload.value),
  },
})

export const actions = slice.actions
export default slice
