import { map } from 'lodash'
import { roles, LOGOUT } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/constants'
import { DASHBOARD, ACCOUNT, USERS_MANAGEMENT, ORDERS } from '../App/routes'
import moment from 'moment'

const { MASTER, USER } = roles

export const getMenuConfig = (keywords = [], publishers = [], groupsByJob = [], role = 'none') => [
  {
    id: 'groups',
    title: 'La tua rassegna',
    fields: map(groupsByJob, (item) => ({ id: item, title: moment(item,'YYYYMMDD').format('LL') })),
    radio: true,
    activeIn: [DASHBOARD],
    role: [MASTER, USER]
  },
  {
    id: 'keywords',
    title: 'Keywords',
    fields: map(keywords, (item) => ({ id: item, title: item })),
    checkbox: true,
    role: [MASTER, USER]
  },
  {
    id: 'publishers',
    title: 'Testate',
    fields: map(publishers, ({ id, name }) => ({ id, title: name })),
    activeIn: [DASHBOARD],
    checkbox: true,
    role: [MASTER, USER]
  },
  /* {
    title: 'Gestione utenti',
    linkTo: `/${USERS_MANAGEMENT}`,
    role: [MASTER]
  }, */
  {
    title: 'Account',
    linkTo: ACCOUNT,
    role: [USER, MASTER]
  },
  /* {
    title: 'Piani e Licenze',
    linkTo: ORDERS,
    role: [MASTER]
  }, */
  {
    title: 'Logout',
    linkTo: LOGOUT,
    role: [USER, MASTER]
  }
].filter(e => e.role.includes(role))
