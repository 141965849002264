"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.publishersPaginationSelectors = exports.uploadsPaginationSelectors = exports.usersPaginationSelectors = exports.feedPaginationSelectors = exports.getPaginationSelectors = void 0;

var _reselect = require("reselect");

var _prop2 = _interopRequireDefault(require("lodash/fp/prop"));

var getPaginationSelectors = function getPaginationSelectors(reducer) {
  var selectState = (0, _prop2["default"])([reducer, 'paginationData']);
  return {
    getLastPage: (0, _reselect.createSelector)(selectState, (0, _prop2["default"])('lastPage')),
    getPagination: (0, _reselect.createSelector)(selectState, (0, _prop2["default"])('pagination'))
  };
};

exports.getPaginationSelectors = getPaginationSelectors;
var feedPaginationSelectors = getPaginationSelectors('feeds');
exports.feedPaginationSelectors = feedPaginationSelectors;
var usersPaginationSelectors = getPaginationSelectors('users');
exports.usersPaginationSelectors = usersPaginationSelectors;
var uploadsPaginationSelectors = getPaginationSelectors('uploads');
exports.uploadsPaginationSelectors = uploadsPaginationSelectors;
var publishersPaginationSelectors = getPaginationSelectors('publishers');
exports.publishersPaginationSelectors = publishersPaginationSelectors;