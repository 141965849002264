import Box from '@stackhouseos/box-core'
import { Devtools } from "@stackhouseos/flow-client";

import StepActions from '@stackhouse/rassegnastampa-core/lib/components/StepActions'
import StepContainer from '@stackhouse/rassegnastampa-core/lib/components/StepContainer'
import FormInput from '@stackhouse/rassegnastampa-core/lib/components/Input/FormInput'
import FormButton from '@stackhouse/rassegnastampa-core/lib/components/Button/FormButton'
import FormSelect from '@stackhouse/rassegnastampa-core/lib/components/Select/FormSelect'
import List from '@stackhouse/rassegnastampa-core/lib/components/ListControl'
import DateRange from '@stackhouse/rassegnastampa-core/lib/components/DateRange'
import EditableList from '@stackhouse/rassegnastampa-core/lib/components/ListControl/EditableList'
import ListHeader from '@stackhouse/rassegnastampa-core/lib/components/ListControl/ListControlHeader'
import Text from '@stackhouse/rassegnastampa-core/lib/components/Text'
import Switch from '@stackhouse/rassegnastampa-core/lib/components/Switch'
import Alert from '@stackhouse/rassegnastampa-core/lib/components/Alert'
import Row from '@stackhouse/rassegnastampa-core/lib/components/Row'
import Card from '@stackhouse/rassegnastampa-core/lib/components/Card'
import Advice from '@stackhouse/rassegnastampa-core/lib/components/Advice'
import LoginForm from '@stackhouse/rassegnastampa-core/lib/components/LoginForm'
import Button from '@stackhouse/rassegnastampa-core/lib/components/ButtonFlow'
import StepProgress from '../components/StepProgress'
import StepHeader from '../components/StepHeader'
import Saga from '../components/Saga'

Box.extendControls({
  Row,
  FormInput,
  FormButton,
  FormSelect,
  StepActions,
  StepContainer,
  StepHeader,
  StepProgress,
  EditableList,
  ListHeader,
  List,
  Text,
  Alert,
  Switch,
  DateRange,
  Card,
  Saga,
  Advice,
  LoginForm,
  Button
})

// Devtools({ port: 8772 });
