import { all } from 'redux-saga/effects'
import auth from '@stackhouse/rassegnastampa-core/lib/containers/Auth/saga'
import stepperState from '@stackhouse/rassegnastampa-core/lib/containers/StepperState/saga'
import { flowSaga } from '@stackhouseos/flow-client'

const context = require.context('../', true, /containers\/[\s\S]*?\/saga.js$/)
const sagas = context.keys().map(filename => context(filename).default)

export default function* root() {
  yield all(
    [
      flowSaga(),
      auth(),
      stepperState(),
      ...sagas.map(saga => saga())
    ]
  )
}