import { last } from 'lodash'
import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import app from '@stackhouse/rassegnastampa-core/lib/utils/api'
import axios from 'axios'
import { updateStepData } from '@stackhouseos/flow-client';
import { actions } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/reducer';
import { realmLogin } from '@stackhouse/rassegnastampa-core/lib/containers/Auth/saga';
import { withLoading } from '@stackhouse/rassegnastampa-core/lib/containers/Loading/withSaga';
import { actionUpdate } from '@stackhouseos/box-core'

function* checkInviteToken({ payload }) {
  const { flowName } = payload

  const token = last(window.location.href.split('/'))

  yield window.localStorage.clear()
  if (app.currentUser)
    yield app.currentUser.logOut()

  try {
    yield axios.get(`${process.env.REACT_APP_ENDPOINT_CHECK_INVITE}?inviteId=${token}`)
    yield put(updateStepData(flowName, 'success', true))
  } catch (error) {
    yield put(updateStepData(flowName, 'error', 'missing-token'))
  } finally {
    yield put({ type: 'flow/next', payload: { flowName } })
  }

}

function* activateUser({ payload }) {
  const { flowName } = payload
  const token = last(window.location.href.split('/'))

  try {
    yield app.currentUser.functions.activateMasterUser(token)
    yield put({ type: 'auth/REFRESH' })
    yield put(updateStepData(flowName, 'success', true))
  } catch (error) {
    console.log("TCL: function*activateUser -> error", error)
    yield put(updateStepData(flowName, 'error', 'missing-token'))
  } finally {
    yield put({ type: 'flow/next', payload: { flowName } })
  }

}

// CODICE DUPLICATO
function* loginUser({ payload }) {
  const { flowName, } = payload
  const { username, password } = yield select(state => state.auth.temp)

  yield window.localStorage.clear()

  if (app.currentUser)
    yield app.currentUser.logOut()

  yield put(actionUpdate('auth', 'temp', undefined))
  yield put(updateStepData(flowName, 'success', undefined))
  yield put(updateStepData(flowName, 'error', undefined))

  try {
    yield app.emailPasswordAuth.registerUser(username.toLowerCase(), password)
    const auth = yield call(realmLogin, username.toLowerCase(), password)
    const { id } = auth

    yield put(actions.setLoginData({
      isLoggedIn: true,
      role: 'none',
      status: 'active',
      userId: id.toString(),
    }))

    yield put(updateStepData(flowName, 'success', true))

  } catch (err) {
    console.log("TCL: function*loginUser -> err", err.message)
    yield put(actions.setError({ message: err.message }))
    yield put(updateStepData(flowName, 'error', err.message))
  } finally {
    yield put({ type: 'flow/next', payload: { flowName } })
  }

}

export default function* appSaga() {
  yield all([
    yield takeLatest('CHECK_INVITE_TOKEN', checkInviteToken),
    yield takeLatest('ACTIVATE_USER', activateUser),
    yield takeLatest('LOGIN_USER', withLoading('login', loginUser)),
  ])
}
