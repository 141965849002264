import React from 'react'
import Flow from "@stackhouseos/flow-client";
import LogoFull from '@stackhouse/rassegnastampa-core/lib/components/LogoFull';
import { breakpoints } from '@stackhouse/rassegnastampa-core/lib/utils/theme'
import { Content, Header, RightPanel, StartPageContainer } from '../../pages/Registration/styled';
import flow from "./invite.flow.json";
import { useWindowWidth } from '@react-hook/window-size';

export default function Invite() {
  const width = useWindowWidth()
  const isScreenLarge = width >= breakpoints.md

  return (
    <StartPageContainer>
      <Header>
        <LogoFull />
      </Header>
      <Content>
        <Flow name={flow.name} elements={flow.elements} reducerName="app" />
      </Content>
      {isScreenLarge && <RightPanel />}
    </StartPageContainer>
  )
}
