import React from 'react'
import { map } from 'lodash'
import { useSelector } from 'react-redux'
import { CheckOutlined } from '@ant-design/icons'
import { useWindowWidth } from '@react-hook/window-size'
import Text from '@stackhouse/rassegnastampa-core/lib/components/Text'
import { theme, breakpoints } from '@stackhouse/rassegnastampa-core/lib/utils/theme'
import {
  RightPanel,
  ProgressStatusCircle,
  StepDescriptionContainer,
  StepsDescriptionsContainer,
  PriceBoxContainer
} from '../styled'
import { getPriceRegistration } from '../../containers/Registration/selectors'

const iconStyle = { color: theme.colors.white }

const StatusCircle = ({ stepNumber, isCurrent, isCompleted }) => (
  <ProgressStatusCircle isCurrent={isCurrent} isCompleted={isCompleted}>
    {isCompleted ? <CheckOutlined style={iconStyle} /> : stepNumber}
  </ProgressStatusCircle>
)

const StepDescription = React.memo(({ title, description, stepNumber, currentStep }) => {
  const isCurrent = stepNumber === currentStep
  const isCompleted = currentStep > stepNumber
  return (
    <StepDescriptionContainer isCurrent={isCurrent}>
      <StatusCircle stepNumber={stepNumber} isCurrent={isCurrent} isCompleted={isCompleted} />
      <div className='step-description-text'>
        <Text block type='white' bold gutterBottom={8}>{title}</Text>
        <Text block type='white' >{description}</Text>
      </div>
    </StepDescriptionContainer>
  )
})

const PriceBox = React.memo(({ price }) => (
  <PriceBoxContainer>
    <div className='price-box'>
      <Text block type='white' size={20} gutterBottom={8} lineHeight={1.2}>
        Prezzo aggiornato della tua rassegna stampa
      </Text>
      <Text bold type='white' size='extraLarge'>
        € {price}
      </Text>
    </div>
  </PriceBoxContainer>
))



export default React.memo(function ProgressControl({ value = 0, stepsDescriptions }) {
  const width = useWindowWidth()
  const price = useSelector(getPriceRegistration)
  
  if (width < breakpoints.md) return null

  return (
    <RightPanel>
      <Text className="progress-header" size="large" type="white" bold>
        Registrati seguendo gli step
      </Text>
      <StepsDescriptionsContainer>
        {map(stepsDescriptions, ({ title, description }, index) => (
          <StepDescription
            key={index}
            title={title}
            description={description}
            stepNumber={index + 1}
            currentStep={value + 1}
          />
        ))}
      </StepsDescriptionsContainer>
      <PriceBox price={price} />
    </RightPanel>
  )
})
