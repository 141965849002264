import { createSelector } from 'reselect'
import _prop from 'lodash/fp/prop'
import _get from 'lodash/get'

const userUpTo = parseInt(process.env.REACT_APP_USER_UPTO_NUMB)

const selectOrdersState = _prop('prices')

export const getPrices = createSelector(
  selectOrdersState,
  (Orders) => _get(Orders, ['items'], [])
)

export const promo = numb => {
  return numb > userUpTo - 1
}

export const planPrice = (rec, prices) => {
  const plan = rec.plan || 'basic'
  const nickname = `rassegnastampa-${plan}`
  const price = prices[nickname]
  return price ? parseFloat(price/100) : 0
}

export const userPrice = (rec, prices, promo) => {
  const interval = rec.subscriptionInterval || 3
  const nickname = `user-${interval}`
  const nicknamePromo = `user-${interval}-20`
  return parseFloat(prices[promo ? nicknamePromo : nickname]/100)
}

export const usersPrice = (users, prices) => {
  return users.reduce((acc, inc, index) => {
    const promo = index > (userUpTo - 1)
    return (acc + userPrice(inc, prices, promo))
  }, 0)
}

export const plansPrices = (plans, prices) => {
  return plans.reduce((acc, inc) => (acc + planPrice(inc, prices)), 0)
}