import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'
import rootReducer from './reducers'
import saga from './sagas'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['app'],
  // blacklist: ['auth', 'menu', 'registration', 'loading'],
  stateReconciler: autoMergeLevel2
}

export default (preloadedState = {}) => {
  // Connect the sagas to the redux store
  const sagaMiddleware = createSagaMiddleware()

  const middleware = [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false
    }),
    sagaMiddleware
  ]

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = configureStore({
    reducer: persistedReducer,
    middleware,
    preloadedState,
    devTools: process.env.NODE_ENV !== 'production',
  })

  const persistor = persistStore(store)

  sagaMiddleware.run(saga).toPromise().catch((e) => {
    if (e?.statusCode === 401 || e.message.indexOf('invalid session') > -1) {
      window.localStorage.clear()
      return window.location.href = '/logout'
    }
    // toast message
    console.error(e)
  });

  return { store, persistor }
}
