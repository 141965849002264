"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.regexCUU = exports.regexPIva = exports.regexEmail = exports.activeTimeMap = exports.activeTime = exports.intervals = void 0;
var intervals = [{
  id: '0 5 * * *',
  name: 'Giornaliero'
}, {
  id: '0 5,13 * * *',
  name: 'Ogni giorno alle 8 e alle 15'
}, {
  id: '0 5 * * 1',
  name: 'Ogni lunedi'
}, {
  id: '0 5 * * 2',
  name: 'Ogni martedi'
}, {
  id: '0 5 * * 3',
  name: 'Ogni mercoledi'
}, {
  id: '0 5 * * 4',
  name: 'Ogni giovedi'
}, {
  id: '0 5 * * 5',
  name: 'Ogni venerdi'
}, {
  id: '0 5 * * 6',
  name: 'Ogni sabato'
}, {
  id: '0 5 * * 0',
  name: 'Ogni domenica'
}, {
  id: '0 5 1 * *',
  name: 'Ogni primo del mese'
}];
exports.intervals = intervals;
var activeTime = [{
  id: '1',
  name: '1 Mesi'
}, {
  id: '2',
  name: '2 Mesi'
}, {
  id: '3',
  name: '3 Mesi'
}, {
  id: '4',
  name: '4 Mesi'
}, {
  id: '6',
  name: '6 Mesi'
}, {
  id: '12',
  name: '12 Mesi'
}];
exports.activeTime = activeTime;
var activeTimeMap = {
  '3': '3 Mesi',
  '6': '6 Mesi',
  '12': '12 Mesi'
};
exports.activeTimeMap = activeTimeMap;
var regexEmail = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$/;
exports.regexEmail = regexEmail;
var regexPIva = /^([0-9]){11}$/;
exports.regexPIva = regexPIva;
var regexCUU = /^([a-zA-Z0-9_-]){7}$/;
exports.regexCUU = regexCUU;